
import { Component, Mixins } from 'vue-property-decorator';
import HeaderInfosMixin from '../header-infos';
import RunDocMixin from '@/mixins/data-operations/doc/run-doc-mixin';
import { storageToTablesRuns } from '@/store/modules/easy-firestore/storage-to-tables-runs';
import { storageToTablesRunsLight } from '@/store/modules/easy-firestore/storage-to-tables-runs-light';

@Component
export default class StorageToTablesRunsItemView extends Mixins(HeaderInfosMixin, RunDocMixin) {
	moduleName: string = storageToTablesRuns.moduleName;
	moduleNameLight: string = storageToTablesRunsLight.moduleName;

	getDestinations() {
		const length = this.item.configuration_context.destinations.length;
		return this.item.configuration_context.destinations
			.map((destination: any, index: number) => {
				const formatTitle = (title: string) => `${title} ${index + 1}/${length}`;
				return [
					{
						component: 'parameters-list',
						props: {
							groupTitle: formatTitle('Destination'),
							tooltip: true,
							description: 'Tables where the files have to be loaded',
							paramItems: [
								{
									id: 'type',
									label: 'Destination Type',
									value: destination.type,
									default: 'None',
								},
								{
									id: 'gcp_project_id',
									label: 'Project ID',
									value: destination.gcp_project_id,
									default: 'None',
								},
								{
									id: 'gbq_dataset',
									label: 'Dataset',
									value: destination.gbq_dataset,
									default: 'None',
								},
							],
						},
					},
					{
						component: 'parameters-table',
						props: {
							tableTitle: formatTitle('Tables'),
							description: 'Tables List to be loaded from files',
							columns: [
								{
									label: 'Table Name',
									field: 'table_name',
								},
								{
									label: 'Filename Template',
									field: 'filename_template',
								},
								{
									label: 'Description',
									field: 'short_description',
								},
							],
							rows: destination.tables,
							overriddenRows: [
								{
									name: 'table_name',
									component: 'TableName',
									props: {
										item: this.item,
										destinations: destination,
									},
								},
							],
							vflexLength: 'xs9',
							lineNumbers: false,
							searchOptionsEnabled: true,
						},
					},
					{
						component: 'parameters-list',
						props: {
							groupTitle: formatTitle('Default Parameters'),
							tooltip: true,
							description: 'Default Paramters for Jobs loading files to tables',
							paramItems: [
								{
									id: 'source_format',
									label: 'Source Format',
									value: destination.source_format,
									default: 'CSV',
									description:
										'Default source format for input files. Only "CSV" or "JSON" are supported. Default : "CSV"',
								},
								{
									id: 'create_disposition',
									label: 'Creation Disposition',
									value: destination.create_disposition,
									default: 'CREATE_IF_NEEDED',
								},
								{
									id: 'write_disposition',
									label: 'Write Disposition',
									value: destination.write_disposition,
									default: 'WRITE_TRUNCATE',
								},
								{
									id: 'skip_leading_rows',
									label: 'Skip Leading Rows',
									value: destination.skip_leading_rows,
									default: '1',
								},
								{
									id: 'field_delimiter',
									label: 'Field Delimiter',
									value: destination.field_delimiter,
									default: '|',
								},
								{
									id: 'quote_character',
									label: 'Quote Character',
									value: destination.quote_character,
									default: '',
								},
								{
									id: 'null_marker',
									label: 'Null Marker',
									value: destination.null_marker,
									default: '',
								},
								{
									id: 'bq_load_job_ignore_unknown_values',
									label: 'Ignore Unknown Values',
									value: destination.bq_load_job_ignore_unknown_values,
									default: false,
								},
								{
									id: 'bq_load_job_max_bad_records',
									label: 'Max Bad Records',
									value: destination.bq_load_job_max_bad_records,
									default: '0',
								},
								{
									id: 'bq_load_job_schema_update_options',
									label: 'Schema Update Options',
									value: destination.bq_load_job_schema_update_options,
									default: '[]',
								},
								{
									id: 'bq_load_job_allow_quoted_newlines',
									label: 'Allow Quoted New Lines',
									value: destination.bq_load_job_allow_quoted_newlines,
									default: false,
								},
								{
									id: 'bq_load_job_allow_jagged_rows',
									label: 'Allow Jagged Rows',
									value: destination.bq_load_job_allow_jagged_rows,
									default: false,
								},
							],
						},
					},
				];
			})
			.flat();
	}

	get itemTabsItems(): any {
		if (Object.keys(this.item).length === 0) return [];
		return [
			this.runDetailsTab,
			this.runLogsTab,
			this.configurationTab,
			this.fullJSONTab,
			this.otherRunsTab,
			this.notesTab,
		];
	}

	get runDetailsData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: this.item.triggering_file,
					viewType: 'run',
					description: null,
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage Run',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{
							id: 'client_type',
							label: 'Client Type',
							value: this.getFormattedClientType(this.item.configuration_context.client_type),
						},
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.configuration_context.client_version),
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Main parameters',
					tooltip: true,
					description: 'Main parameters of the Table to Storage Run',
					paramItems: [
						{
							id: 'configuration_id',
							label: 'Configuration Id',
							value: this.item.configuration_id,
						},
						{
							id: 'triggering_file',
							label: 'Triggering File',
							value: this.item.triggering_file,
						},
						{
							id: 'matching_filename_template',
							label: 'Matching Filename Template',
							value: this.item.matching_filename_template,
						},
						{
							id: 'source_storage',
							label: 'Source Storage',
							value: this.item.source_storage,
						},
					],
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Run Details',
					tooltip: true,
					description: 'Details of the Table to Storage Run',
					paramItems: [
						{
							id: 'dag_type',
							label: 'Configuration Type',
							value: this.item.dag_type,
						},
						{
							id: 'job_id',
							label: 'Job Id',
							value: this.item.job_id,
						},
						{
							id: 'dag_execution_date',
							label: 'Execution Date',
							value: this.$moment(this.item.dag_execution_date, '').format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'duration',
							label: 'Duration',
							value: this.item.duration?.split('.')[0],
						},
						{
							id: 'dag_run_id',
							label: 'Run Id',
							value: this.item.dag_run_id,
						},
						{
							id: 'dag_generator_version',
							label: 'Generator Version',
							value: this.item.dag_generator_version,
						},
						{
							id: 'max_active_runs',
							label: 'Max Active Runs',
							value: this.item.configuration_context.max_active_runs,
						},
					],
				},
			},
		];
	}

	get configurationData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					activeHeader: false,
					viewId: this.item.configuration_id,
					viewType: 'conf',
					description: null,
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage configuration',
					paramItems: [
						{
							id: 'account',
							label: 'Account',
							value: this.item.account,
						},
						{
							id: 'environment',
							label: 'Environment',
							value: this.item.environment,
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'Source Storage',
					description: 'Source Storage of the files to load',
					columns: [
						{
							label: 'Type',
							field: 'source_type',
							width: '100px',
						},
						{
							label: 'Storage ID',
							field: 'gcs_source_bucket',
							width: '200px',
						},
						{
							label: 'Destination Folder',
							field: 'gcs_source_prefix',
							width: '200px',
						},
						{
							label: 'Archive Folder',
							field: 'gcs_archive_prefix',
							width: '200px',
						},
					],
					rows: [
						{
							source_type: this.item.configuration_context.source.type,
							gcs_source_bucket: this.item.configuration_context.source.gcs_source_bucket,
							gcs_source_prefix: this.item.configuration_context.source.gcs_source_prefix,
							gcs_archive_prefix: this.item.configuration_context.source.gcs_archive_prefix,
						},
					],
					vflexLength: 'xs9',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			...this.getDestinations(),
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Update information',
					paramItems: [
						{
							id: 'updated_date',
							label: 'Updated date',
							value: this.$moment(
								this.item.configuration_context.update_date || this.item.configuration_context.updated_date,
								''
							).format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'updated_by',
							label: 'Updated by',
							value: this.item.configuration_context.updated_by,
						},
					],
				},
			},
		];
	}
}
