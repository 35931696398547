import { render, staticRenderFns } from "./ItemView.vue?vue&type=template&id=6ffbfbaa&"
import script from "./ItemView.vue?vue&type=script&lang=ts&"
export * from "./ItemView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tailer-studio/tailer-studio/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ffbfbaa')) {
      api.createRecord('6ffbfbaa', component.options)
    } else {
      api.reload('6ffbfbaa', component.options)
    }
    module.hot.accept("./ItemView.vue?vue&type=template&id=6ffbfbaa&", function () {
      api.rerender('6ffbfbaa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/data-operations/storage-to-tables/runs/ItemView.vue"
export default component.exports